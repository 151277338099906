import { KeywordScrollListEntry } from "frontend/contentful/schema/blocks";
import {
  KeywordScrollList,
  type KeywordProps,
} from "design-system/sites/home/keyword-scroll-list/keyword-scroll-list";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { useContentful } from "frontend/hooks/use-contentful";

export const ContentfulKeywordScrollList =
  createContentfulComponent<KeywordScrollListEntry>(
    ({ entry, createEditAttributes }) => {
      const { getEntries } = useContentful();

      let keywords: Array<KeywordProps> = [];

      if (entry.fields?.textKeywords) {
        keywords = entry.fields.textKeywords.map((label) => ({
          label,
          editAttributes: createEditAttributes({
            entry,
            fieldId: "textKeywords",
          }),
        }));
      }

      const keywordEntries = getEntries(entry.fields.linkKeywords);

      if (keywordEntries) {
        keywords = keywordEntries.map((link) => ({
          label: link.fields.text,
          editAttributes: createEditAttributes({
            entry: link,
            fieldId: "title",
          }),
        }));
      }

      return <KeywordScrollList keywords={keywords} />;
    },
  );
