import { EditAttributes } from "design-system/types/types";
import { AnchorHTMLAttributes, forwardRef } from "react";

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  editAttributes?: EditAttributes;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { href, target, children, className, rel, editAttributes, ...props },
    ref,
  ) => {
    if (!target) target = "_blank";

    if (href?.startsWith("/") || href?.includes(".hbs.edu")) {
      if (href.includes("webassets.hbs.edu")) target = "_blank";
      else target = "_self";
    }

    return (
      // eslint-disable-next-line react/forbid-elements
      <a
        {...editAttributes}
        href={href}
        target={target}
        className={className}
        rel={rel}
        {...props}
        ref={ref}
      >
        {children}
      </a>
    );
  },
);
