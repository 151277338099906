import "./keyword-scroll-list.scss";

import { useEffect, useState, CSSProperties, useRef } from "react";
import cn from "clsx";
import {
  ComponentHeader,
  ComponentHeaderProps,
} from "design-system/components/primitives/component-header/component-header";

import { EditAttributes } from "design-system/types/types";

export interface KeywordProps {
  label?: string;

  editAttributes?: EditAttributes;
}

export interface ElementScroll {
  top: number;
  bottom: number;
}

export interface KeywordScrollListProps {
  keywords: Array<KeywordProps>;
  headerContent?: ComponentHeaderProps;
}

/**
 * This component is only intended to be used on the homepage.
 *
 * ## See it in use on...
 * - The [homepage](/story/example-pages-homepage--homepage)
 */

export function KeywordScrollList({
  keywords,
  headerContent,
}: KeywordScrollListProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [elementScroll] = useState<ElementScroll>({ top: 0, bottom: 0 });

  // Sort keywords once on component initialization
  const [sortedKeywords, setSortedKeywords] = useState<Array<KeywordProps>>([]);

  useEffect(() => {
    setSortedKeywords([...keywords].sort(() => Math.random() - 0.5));
  }, [keywords]); // This ensures that sorting happens only once when the component mounts or when keywords prop changes

  const numWordsOnRow =
    Math.floor(sortedKeywords.length / 3) <= 3
      ? Math.floor(sortedKeywords.length / 3)
      : 3;

  let sliceIndex = 0;

  const [row1, row2, row3] = Array.from({ length: 3 }).reduce<
    [Array<KeywordProps>, Array<KeywordProps>, Array<KeywordProps>]
  >(
    (rows, _, index) => {
      const slicedKeywords = sortedKeywords.slice(
        sliceIndex,
        sliceIndex + numWordsOnRow,
      );

      // If keywords are shorter than approximate number of characters needed to
      // fill the space, add more keywords
      while (
        slicedKeywords.map((keyword) => keyword.label).join().length < 50
      ) {
        sliceIndex = (sliceIndex + 1) % sortedKeywords.length;
        const nextKeyword = sortedKeywords[sliceIndex];

        if (nextKeyword) {
          slicedKeywords.push(nextKeyword);
        } else {
          break;
        }
      }

      rows[index] = slicedKeywords;

      sliceIndex = (sliceIndex + numWordsOnRow) % sortedKeywords.length;

      return rows;
    },
    [[], [], []],
  );

  const [scrollPercentage, setScrollPercentage] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(0);

  function updateVariables() {
    if (ref.current) {
      const elBoundingBox = ref.current.getBoundingClientRect();
      const elHeight = elBoundingBox.height;
      const windowHeight = window.innerHeight;
      const elTop = elBoundingBox.top;
      const speedControl = 0.65;

      if (elTop - windowHeight <= 0 && elTop + elHeight > 0) {
        setScrollPercentage(
          (1 - (elTop + elHeight) / (elHeight + windowHeight)) * speedControl,
        );
        setWindowWidth(window.innerWidth);
      }
    }
  }

  useEffect(() => {
    const prefersReducedMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)",
    );
    if (prefersReducedMotion?.matches === true) {
      return;
    }
    updateVariables();

    function onScroll() {
      updateVariables();
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [elementScroll.bottom, elementScroll.top]);

  const wrapperClass = cn(
    "hbs-global-align-full",
    "hbs-component--keyword-scroll-list",
  );

  return (
    <div
      className={wrapperClass}
      data-chromatic="ignore"
      data-region="homepage-keyword-scroll-list"
      style={
        {
          "--keyword-scroll-offset": scrollPercentage,
          "--window-width": `${windowWidth}px`,
        } as CSSProperties
      }
      ref={ref}
    >
      {headerContent && <ComponentHeader {...headerContent} />}

      <div className="hbs-keyword-scroll-list" aria-hidden="true">
        {[row1, row2, row3].map((row, index) => (
          <p className="hbs-keyword-scroll-list__row" key={index}>
            {row.map((keyword, i) => (
              <span
                key={i}
                className="hbs-keyword-scroll-list__keyword"
                {...keyword.editAttributes}
              >
                {keyword.label}
              </span>
            ))}
          </p>
        ))}
      </div>

      <span className="hbs-global-visually-hidden">
        On scroll animation of three rows of HBS Programs and Initiatives.
      </span>
    </div>
  );
}
